import { configureStore } from '@reduxjs/toolkit'
import sidebarReducer from 'src/components/sidebarSlice'
import userReducer from './user/user.reducer'

const store = configureStore({
  reducer: {
    user: userReducer,
    sidebar: sidebarReducer,
  },
})

export default store