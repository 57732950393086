import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthContext } from './helpers/AuthContext';
import { DisableActionContext } from './helpers/DisableActionContext';
import { ReportContext } from './helpers/ReportContext';
import { useState } from 'react';
import axios from 'axios';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/user/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const [authState, setAuthState] = useState({
    username: "", 
    id: 0, 
    status: false,
    basic: false,
    admin: false,
    superadmin: false,
    techadmin: false,
    role: ""
  });
  const [disableAction, setDisableAction] = useState(false);
  const [reportState, setReportState] = useState('Activity Summary');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_CLIENT}/auth/validate`, { 
      headers: {
        'x-access-token': sessionStorage.getItem('access-token'),
      },
    }).then((response) => {
      if (response.data.error) {
        setAuthState({...authState, status: false});
      } else {
        setAuthState({
          username: response.data.username, 
          id: response.data.id, 
          status: true,
          basic: response.data.basic,
          admin: response.data.admin,
          superadmin: response.data.superadmin,
          techadmin: response.data.techadmin,
          role: response.data.role,
        });
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{authState, setAuthState}}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {/* If user is authenticated then show the home page. If not then redirect to login page and don't show any other page */}
            {!authState.status ? (
            <>
              <Route path="/" name="Login Page" render={(props) => <Login {...props} />} />
            </>
            ) : ( 
              <>
                {/* ALL CONTEXTS */}
                <DisableActionContext.Provider value={{disableAction, setDisableAction}}>
                <ReportContext.Provider value={{reportState, setReportState}}>
                  <div className={disableAction ? "content-pointer-event-none" : "jes" }>
                    <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
                  </div>
                </ReportContext.Provider>
                </DisableActionContext.Provider>
                <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              </>
            )}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

export default App;
